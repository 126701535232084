<app-base-page-item
  [data]="data"
  [recordStatusActions]="recordStatusActions"
  [extendLockTimeAction]="extendLockTimeAction"
  [showLocked]="showLocked"
  [showCommentsButton]="editMode"
  [showStateMachineAuditTrail]="showStateMachineAuditTrail"
  [showHistoryButton]="editMode"
  [showAuditButton]="editMode"
  [showAddCommentButton]="editMode"
  [showShareButton]="viewModeService.viewMode == 'edit'"
  [breadCrumb]="breadCrumb"
  [sourceCode]="itemId"
  [hasEditChanges]="hasEditChanges$ | async"
  [fromType]="fromType"
  [showViewSwitch]="editMode"
  [showRelations]="editMode && viewModeService.viewMode != 'create'"
  [showViewSwitch]="editMode && viewModeService.viewMode != 'create'"
  (onViewModeChange)="onViewModeChange($event)"
  [moduleKeyword]="moduleKeyword"
>
  <ng-container page-header>
    <div class="flex flex-wrap gap-3">
      <h4 class="mb-0">
        {{ 'general.actions.' + (editMode ? viewModeService.viewMode : 'add') | translate }}
        {{
          'modules.' +
            routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword]) +
            '.' +
            toCamelCase(this.moduleKeyword) +
            '.' +
            toCamelCase(this.moduleKeyword) | translate
        }}
      </h4>
      @if (editMode) {
        <div class="align-self-center">
          <app-badge-item
            [status]="data?.status"
            [text]="data?.status"
            (click)="showStateMachine()"
            class="cursor-pointer"
          ></app-badge-item>
        </div>
      }
    </div>
  </ng-container>

  <app-remediation-plan-item-form
    (formSubmit)="onSubmit($event)"
    (formSubmitSave)="onSubmit($event, 'PAGE')"
    (formSubmitInPlace)="onSubmit($event, 'NONE')"
    (cancelEvent)="onCancel($event)"
    [resetForm]="resetFormTrigger"
    (valueEditChanged)="valueEditChanged($event)"
    [formData]="data"
    [editMode]="editMode"
    [viewModeOnly]="data?.status === 'COMPLETED' && viewModeService.viewMode != 'create'"
  ></app-remediation-plan-item-form>

  <ng-container page-actions>
    @if (viewMode == 'edit' && showRuleHandler) {
      <app-flat-rule-handler
        [actionMap]="actionsMap"
        [config]="{ requestService: requestService, itemId: itemId }"
      ></app-flat-rule-handler>
    }
  </ng-container>
  @if (editMode) {
    <ng-container below-main-card>
      <app-base-page [showViewSwitch]="editMode">
        <ng-container page-header>
          <div class="flex flex-wrap gap-3">
            <h4 class="mb-0">Issues</h4>
          </div>
        </ng-container>
        <div class="grid p-fluid">
          <div class="col-12 md:col-9">
            <app-table
              [cols]="cols"
              [tableActions]="[]"
              [actionCol]="null"
              [inputData]="linkedIssues"
              [offlineConfig]="offlineConfig"
              [passIndexWithRowDataToAction]="true"
              [sessionStorageKey]="undefined"
              [selectionMode]="null"
              [pageInfo]="linkedIssuesTableEvent.pageInfo"
              (filtersChanged)="fetchIssues($event)"
              class="w-full"
            ></app-table>
          </div>
          <div class="col-12 md:col-3 widget-country-graph">
            <p-chart type="doughnut" [data]="cdata" [options]="options"></p-chart>
            <div class="country-content">
              <ul>
                @for (listItem of chartData | keyvalue; track listItem) {
                  <li class="flex justify-content-between align-items-center">
                    <div class="flex justify-content-between align-items-center">
                      <div [class]="'color bg-' + badgeColors[$any(listItem?.key)] + '-500 shadow-1'"></div>
                      <span>{{ $any(listItem.key) | humanize }}</span>
                    </div>
                    <span [pTooltip]="'Amount: ' + listItem.value" tooltipPosition="top"
                      >{{
                        listItem.value ? (($any(listItem.value) / statusTotal) * 100 | parseToFixedNumber: 'int') : '0'
                      }}%</span
                    >
                  </li>
                }
              </ul>
            </div>
          </div>
        </div>
      </app-base-page>
      @if (remediationActionTableCardContent && showRemediationActionTable && itemId) {
        <div class="card">
          <app-widget-table
            [setDefaultActions]="false"
            class=""
            [title]="remediationActionTableCardContent?.name"
            [cardContent]="remediationActionTableCardContent"
            [styleClass]="'relative m-0 '"
            style="height: calc(100% - 25px)"
            [pageActions]="remediationActionPageActions"
            [tableActions]="remediationActionTableActions"
            (onRowSelectionChange)="onRowSelection($event)"
          >
          </app-widget-table>
        </div>
      }
    </ng-container>
  }
</app-base-page-item>
